export default [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'الإعدادات والضبط',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'البيانات العامة للمدرسة',
        route: 'settings-general',
      },
      {
        title: 'بيانات المواد',
        route: 'settings-subjects',
      },
      {
        title: 'الصفوف والفصول',
        route: 'settings-classes',
      },
      {
        title: 'بيانات المعلمين',
        route: 'settings-teachers',
      },
    ],
  },
  {
    title: 'تجهيز الجدول',
    route: 'table',
    icon: 'CoffeeIcon',
  },
  {
    title: 'الجداول المحفوظة',
    route: 'tables',
    icon: 'SaveIcon',
  },
]
